<template>
  <div class="customers">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="blue"
    ></v-progress-linear>
    <div class="customers-wrapper">
      <!-- навигац. цепочка -->
      <div class="nav-chain">
        <div class="chain">
          <div class="link">Клиенты</div>
          <!-- кнопка загрузки файла -->
          <btn-upload
            v-show="customers.length > 0"
            @update="
              fileToUpload = $event;
              uploadModalShow = true;
            "
          ></btn-upload>
        </div>
      </div>
      <!-- таблица с файлами -->
      <div class="table-wrapper" v-show="customers.length > 0">
        <v-simple-table class="table" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="sticky-header text-left" :class="scrollPosition >= 145 ? 'shadow' : null">Имя файла</th>
                <th class="sticky-header text-left" :class="scrollPosition >= 145 ? 'shadow' : null">Кол-во клиентов</th>
                <th class="sticky-header text-left" :class="scrollPosition >= 145 ? 'shadow' : null">Дата загрузки</th>
                <th class="sticky-header text-left" :class="scrollPosition >= 145 ? 'shadow' : null">Время загрузки</th>
                <th class="sticky-header text-left" :class="scrollPosition >= 145 ? 'shadow' : null">Формат файла</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in customers.slice(0, sliceValue)" :key="item.id">
                <td>{{ item.filename }}</td>
                <td>{{ item.clients_count }}</td>
                <td>{{ item.created_at.split("T")[0] | convertedDate() }}</td>
                <td>{{ item.created_at.split("T")[1].slice(0, 5) }}</td>
                <td style="text-transform: uppercase">
                  {{ item.file_format }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <!-- если нет категорий созданных -->
    <div class="noCards" v-show="customers.length === 0 && !uploadModalShow">
      <div class="text">
        Для добавления первой информации о клиенте нажмите кнопку “Загрузить”
      </div>
      <!-- кнопка загрузки файла -->
      <btn-upload
        @update="
          fileToUpload = $event;
          uploadModalShow = true;
        "
      ></btn-upload>
    </div>
    <!-- модалка подтверджения удаления -->
    <accept-upload-modal
      :show="uploadModalShow"
      @closed="
        uploadModalShow = false;
        fileToUpload = null;
      "
      @accepted="uploadFile()"
    />
  </div>
</template>


<script>
import axios from "axios";
import acceptUploadModal from "@/components/Customers/acceptUploadModal";
import btnUpload from "@/components/Customers/btnUpload";
export default {
  components: {
    acceptUploadModal,
    btnUpload,
  },
  data() {
    return {
      uploadModalShow: false,
      customers: [],
      fileToUpload: null,
      loading: false,
      sliceValue: 20,
      scrollPosition: null,
      // customersTest: [],
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      /* Если высота окна + высота прокрутки больше или равны высоте всего документа, то  */
      if (
        window.innerHeight + window.scrollY >= document.body.clientHeight &&
        this.customers.length > this.sliceValue
      ) {
        this.sliceValue += 20;
      }
    },
    uploadFile() {
      this.loading = true;
      const formData = new FormData();
      formData.append("file", this.fileToUpload.file);
      return new Promise((resolve, reject) => {
        axios
          .post("/client_info/", formData)
          .then((resp) => {
            this.uploadModalShow = false;
            // this.fileToUpload = null;
            this.getCustomers();
            this.loading = false;
            resolve(resp);
          })
          .catch((err) => {
            this.fileToUpload = null;
            this.loading = false;
            reject(err);
            console.log(err);
          });
      });
    },
    getCustomers() {
      return new Promise((resolve, reject) => {
        axios
          .get("/client_info/")
          .then((resp) => {
            this.customers = resp.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    handleFileUpload() {
      this.file.ref = this.$refs.file.files[0];
      this.file.name = this.$refs.file.files[0].name;
    },
  },
  filters: {
    convertedDate(rowDate) {
      let dateParts = rowDate.split("-");
      return [dateParts[2], dateParts[1], dateParts[0]].join(".");
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  created() {
    this.getCustomers();
    // for (let i = 1; i <= 200; i++) {
    //   this.customersTest.push({
    //     clients_count: 100,
    //     created_at: "2021-01-19T15:01:16.930000",
    //     file_format: "xlsx",
    //     filename: "stats",
    //     id: i,
    //     is_processed: false,
    //   });
    // }
  },
};
</script>


<style lang="scss" scoped>
.customers {
  position: relative;
  height: 100%;
  &-wrapper {
    padding: 0 170px 0 170px;
    height: 100%;
  }
}
.nav-chain {
  padding-top: 0;
  padding-bottom: 0;
  z-index: 11;
}
.chain {
  padding-top: 40px;
  padding-bottom: 58px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(54, 66, 165, 0.65);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.v-data-table .sticky-header {
  position: sticky;
  top: 70px;
  background: white;
  z-index: 2;
  &.shadow {
    box-shadow: 3px 5px 4px 0px rgba(0, 0, 0, 0.2) !important;
  }

  // border-bottom: thin solid rgba(0, 0, 0, 0.2);
}
.table {
  &-wrapper {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    background: white;
    margin-bottom: 75px;
  }
  th {
    padding: 23px !important;
  }
  td {
    padding: 0 23px !important;
  }
}
.noCards {
  z-index: 10;
  position: absolute;
  width: 100%;
  background: rgba(108, 108, 108, 0.15);
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    max-width: 408px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    margin-bottom: 32px;
  }
}
</style>