<template>
  <div class="btnUpload">
    <input
      class="btnUpload__input"
      type="file"
      :id="'file_' + id"
      ref="file"
      v-on:change="handleFileUpload()"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    />
    <v-btn color="white" class="btn" @click="$refs.file.click()">
      <span class="btn__text">Загрузить</span>
      <v-icon size="32" color="#3642a5">mdi-cloud-upload</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      file: {
        ref: "",
        name: "",
      },
    };
  },
  methods: {
    handleFileUpload() {
      this.file.ref = this.$refs.file.files[0];
      this.file.name = this.$refs.file.files[0].name;
      this.$emit("update", {
        file: this.file.ref,
        id: this.id,
      });
      this.$refs.file.value = '';}
  },
};
</script>

<style lang="scss" scoped>
.btnUpload {
  position: relative;
  cursor: pointer;
  &__input {
    opacity: 0;
    width: 167px;
    height: 47px;
  }
  .btn {
    top: 0;
    position: absolute;
    width: 167px;
    height: 47px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      color: #3642a5;
      margin-right: 11px;
      letter-spacing: 0.16px;
    }
  }
}
</style>